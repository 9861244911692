import { useState, useRef } from "react";
import { API_URL } from "../utils/helpers";

import Modal from "../components/Modal";
import InputUncontrolled from "../components/InputUncontrolled";
import Select from "../components/Select";
import Msg from "../components/Msg/index";
import Submit from "../components/Submit/index";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const programs = [
	{
		name: "Behavioral Health at Nicklaus Children’s Palm Beach Gardens Outpatient Center",
		description: "",
		more: {
			type: "",
			id: "",
		},
		show: false,
	},
	{
		name: "Clinics Can Help - Cribs for Kids",
		description:
			"Clinics Can Help - Cribs for Kids is striving to provide 1,000 cribs for infants in Palm Beach County to ensure they have a safe space to sleep.",
		more: {
			type: "vimeo",
			id: "889942496",
		},
		show: false,
	},
	{
		name: "Els for Autism",
		description:
			"Founded by Ernie and Liezl Els, Els for Autism’s global mission is to transform the lives of individuals with autism and those who care for them through lifespan services and collaborative partnerships. The RUBI Autism Network Parent Training program teaches parents to use applied behavior analysis techniques to manage their child’s challenging behavior and to teach new skills.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/els-for-autism.pdf",
		},
		show: true,
	},
	{
		name: "HaitiChildren",
		description:
			"HaitiChildren’s helps provide medical care, housing, food and more for sick, disabled, and abandoned children. With severe social unrest and insecurity throughout the country, HaitiChildren continues to push forward with their mission to help save these precious children.",
		more: {
			type: "yt",
			id: "yKM9hoVgOmU",
		},
		show: false,
	},
	{
		name: "Hope to Dream",
		description:
			"Hope to dream believes that every child should have a good night’s sleep and a bed to call their own. Since 2010, Hope to Dream has provided children in need with twin mattresses, bed frames, bedding and pillow. Their efforts have given over 140,000 beds to children in need, improving their sleeping conditions and leading to a higher quality of life.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/hope-to-dream.pdf",
		},
		show: true,
	},
	{
		name: "Mia’s Miracles",
		description:
			"Founded by Maria and Camilo Villegas in honor of their precious daughter, Mia who lost her battle with brain and spine cancer in 2020 at 22 months old. Mia’s Miracles provides small blessings with large impacts, bringing smiles and positivity to children and families facing challenging circumstances.",
		more: {
			type: "vimeo",
			id: "1032097601",
		},
		show: true,
	},

	{
		name: "MiracleFeet",
		description:
			"2 million children live with the pain and stigma of untreated clubfoot, a condition that affects at least 1 in 800 globally. MiracleFeet is on a mission to create universal access to treatment for this leading cause of physical disability worldwide.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/miracle-feet.pdf",
		},
		show: true,
	},
	{
		name: "Neurodevelopmental Center of Excellence at Nicklaus Children’s Palm Beach Gardens Outpatient Center",
		description:
			"Nicklaus Children’s Hospital aims to develop a comprehensive, multi-disciplinary outpatient program for the diagnosis and management of neurodevelopmental conditions so that patients and families do not have to leave the Palm Beach Area for world-class care.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/neurodevelopmental.pdf",
		},
		show: true,
	},
	{
		name: "Nicklaus Children’s Hospital",
		description:
			"Nicklaus Children’s Hospital proudly serves as one of the few remaining standalone pediatric hospitals in the U.S. Touching the lives of nearly 1 million children each year, they remain dedicated to reach many more kids, both near and far.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/nch.pdf",
		},
		show: false,
	},
	{
		name: "Nicklaus Children’s Palm Beach Gardens Outpatient Center",
		description:
			"Located in Legacy Place in Palm Beach Gardens this center offers consultation, evaluation, and treatment services when your child needs non-emergency care. Services include Urgent Care, Diagnostics and Testing, Rehabilitation Services, and Pediatric Specialist Appointments.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/nch-pbg.pdf",
		},
		show: false,
	},
	{
		name: "Oliver Patch Project",
		description:
			"Oliver Patch Project’s mission is to create a FREE, fun, engaging platform where children with childhood cancers and their families can connect through a series of unique patches created just for them. OPP empowers kids to find their inner warriors, as they endure life changing milestone events, by providing a bit of sunshine throughout their cancer journey.",
		more: {
			type: "vimeo",
			id: "1032044676",
		},
		show: true,
	},
	{
		name: "Oscar Strong Foundation",
		description:
			"Founded by Sarah and Riley Stuebe in honor of their son Oscar, who at age 6 suffered from Commotio Cordis, cardiac arrest following a blow to the chest while playing baseball. Oscar Strong Foundation is dedicated to raising awareness of Commotio Cordis, which is one of the most common causes of death in young athletes. With a goal to educate people on two critical steps to perform CPR and how to use an AED.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/oscar-strong.pdf",
		},
		show: true,
	},
	{
		name: "Pediatric Emergency Department at Jupiter Medical Center",
		description:
			"In partnership with Nicklaus Children’s Hospital the Pediatric Emergency Department at Jupiter Medical Center is a separate wing with dedicated pediatric physicians and nurses available 24/7.",
		more: {
			type: "vimeo",
			id: "1032097574",
		},
		show: true,
	},
];

const Vote = ({ handleStatus }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		programVotes: Array(
			programs.filter((program) => {
				return program.show;
			}).length
		).fill(0),
		totalVotes: 0,
	});
	const [msg, setMsg] = useState({});
	const [modal, setModal] = useState({
		open: false,
		type: "",
		id: "",
	});

	const formElement = useRef();

	const handleUpdate = (name, value) => {
		if (name === "program") {
			const split = value.split("|");
			const index = parseInt(split[0], 10);
			const votes = parseInt(split[1], 10);

			const newArray = names.programVotes;
			newArray[index] = votes;
			const sum = newArray.reduce((partialSum, a) => partialSum + a, 0);
			setNames((names) => ({
				...names,
				programVotes: newArray,
				totalVotes: sum,
			}));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=gcVoting`);
		let formData = new FormData(formElement.current);
		formData.append(
			"programs",
			JSON.stringify(programs.filter((program) => program.show))
		);
		formData.append("programVotes", JSON.stringify(names.programVotes));
		formData.append("totalVotes", JSON.stringify(names.totalVotes));

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				const obj = {
					...json,
				};
				handleStatus(json.id, 1, obj);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleCloseModal = () => {
		setModal({
			open: false,
			type: "",
			id: "",
		});
	};

	return (
		<>
			{modal.open && (
				<Modal
					type={modal.type}
					id={modal.id}
					handleCloseModal={handleCloseModal}
				/>
			)}

			<h2 style={{ color: "var(--blue)", fontSize: "1.5rem" }}>
				2024 Golden Cub Ambassadors Club Voting
			</h2>

			<p style={{ marginTop: "0" }}>
				Congratulations on another impactful year! We are proud to announce we
				have raised $292,000.
			</p>

			<p>
				How it works: Each Member will receive 20 votes to cast across any of
				the 8 programs. Any combination of exactly 20 votes must be selected
				before the form can be submitted.
			</p>

			<p>
				Grants will be determined based on a percentage of the total number of
				votes.
			</p>

			<p>Last day to submit your vote is Friday, December 6.</p>

			<form
				ref={formElement}
				method="post"
				action="/"
				onSubmit={(e) => handleSubmit(e)}
			>
				<h3 style={{ color: "var(--blue)", marginTop: "25px" }}>Member Name</h3>

				<div className="two">
					<div>
						<InputUncontrolled
							label="First Name"
							req={true}
							name="fname"
							update={handleUpdate}
							errors={errors}
							autocomplete="given-name"
						/>
					</div>
					<div>
						<InputUncontrolled
							label="Last Name"
							req={true}
							name="lname"
							update={handleUpdate}
							errors={errors}
							autocomplete="family-name"
						/>
					</div>
				</div>

				<h3 style={{ marginBottom: "25px", color: "var(--blue)" }}>
					Cast Your Votes
				</h3>

				<table>
					<thead>
						<tr>
							<th className="left">2024 Proposed Funding Opportunities</th>
							<th style={{ width: "15%", minWidth: "125px" }}>Votes</th>
						</tr>
					</thead>
					<tbody>
						{programs
							.filter((program) => {
								return program.show;
							})
							.map((item, index) => {
								return (
									<tr key={index}>
										<td className="left">
											<h3
												style={{ textTransform: "none", marginBottom: "5px" }}
											>
												{item.name}
											</h3>
											<p
												style={{
													marginTop: "0",
													fontStyle: "italic",
													fontSize: "0.95rem",
													lineHeight: "20px",
												}}
											>
												{item.description}
											</p>

											{item.more && item.more.type === "pdf" ? (
												<p>
													<a
														itemProp="url"
														href={item.more.url}
														data-hover-background-color="#cf2861"
														data-hover-border-color="#cf2861"
														data-hover-color="#ffffff"
														className="qbutton small center default"
														style={{
															color: "rgb(255, 255, 255)",
															borderColor: "rgb(182, 32, 83)",
															fontWeight: "400",
															fontSize: "14px",
															borderRadius: "32.5px",
															backgroundColor: "rgb(182, 32, 83)",
														}}
														target="_blank"
														rel="noreferrer"
													>
														Learn More
													</a>
												</p>
											) : item.more && item.more.type ? (
												<button
													type="button"
													onClick={() =>
														setModal({
															open: true,
															type: item.more.type,
															id: item.more.id,
														})
													}
													style={{
														margin: "0",
														color: "rgb(255, 255, 255)",
														border: "none",
														borderColor: "rgb(182, 32, 83)",
														fontWeight: "400",
														fontSize: "14px",
														borderRadius: "32.5px",
														background: "rgb(182, 32, 83)",
														appearance: "none",
														cursor: "pointer",
														height: "30px",
														lineHeight: "30px",
														padding: "0 17px",
														display: "inline-block",
														textTransform: "uppercase",
														letterSpacing: "1px",
														textAlign: "center",
													}}
												>
													Learn More
												</button>
											) : (
												<div />
											)}
										</td>
										<td>
											<Select
												label=""
												req={false}
												name="program"
												value=""
												data={[{ value: `${index}|0`, name: "--" }].concat(
													Array.from(Array(20).fill(), (_, i) => i + 1).map(
														(num) => {
															return {
																value: `${index}|${num}`,
																name: num,
															};
														}
													)
												)}
												update={handleUpdate}
												errors={errors}
											/>
										</td>
									</tr>
								);
							})}
					</tbody>
					<tfoot>
						<tr>
							<th className="right">
								<p
									style={{
										fontSize: "1.35rem",
										marginTop: "0",
										marginBottom: "0",
									}}
								>
									Vote Total
								</p>
							</th>
							<th>
								<p
									style={{
										fontSize: "1.35rem",
										marginTop: "0",
										marginBottom: "0",
										color:
											names.totalVotes === 20
												? "var(--success)"
												: "var(--error)",
									}}
								>
									{names.totalVotes}
								</p>
							</th>
						</tr>
					</tfoot>
				</table>

				<div className="msg-submit">
					{msg.type && <Msg data={msg} />}

					<div
						className={msg.type === "working" ? "hidden" : "submit-container"}
					>
						<Submit name="Submit Votes" icon={faChevronCircleRight} />
					</div>
				</div>

				<p>
					<small>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a
							href="/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>
						.
					</small>
				</p>
			</form>
		</>
	);
};

export default Vote;
